/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { useLazyQuery } from "@apollo/client";
import GET_PAGES from "src/graphql/query/settings/getPages";
import useActiveUserDetail from "src/utils/shared/useActiveUserDetail";
import NoRecordFound from "src/components/shared/molecules/NoRecordFound";
import CircularProgress from "@mui/material/CircularProgress";
import { FacebookFunc } from "src/utils/auth/Facebook";
import { enqueueSnackbar } from "notistack";
import UpperComponent from "./upperComponent";
import LowerComponent from "./lowerComponent";
import { User } from "./upperComponent/types";
import useStyle from "./Styles";

declare global {
  interface Window {
    FB: any;
  }
}

const Facebook: React.FC = () => {
  const { classes } = useStyle();
  const [pagesData, setPagesData] = useState<any[]>([]);

  const [pageExistingError, setPageExistingError] = useState<string | null>(
    null,
  );
  const [loggedInUserDetail, setloggedInUserDetail] = useState<User | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { mainSuperAdminId } = useActiveUserDetail();

  useEffect(() => {
    FacebookFunc.fbInt();
  }, []);

  useEffect(() => {
    const getLoginStatus = async () => {
      if (window.FB) {
        try {
          setIsLoading(true);
          window.FB.getLoginStatus(async function getStatus(response: any) {
            if (response.status === "connected") {
              const { accessToken } = response.authResponse;
              const user = await axios.get(
                `https://graph.facebook.com/me?access_token=${accessToken}&fields=id,name,email,picture.width(640).height(640)`,
              );

              setloggedInUserDetail(user.data);
            }
          }, true);
        } catch {
          enqueueSnackbar("Something went wrong. Try again.", {
            variant: "error",
          });
        } finally {
          setIsLoading(false);
        }
      }
    };
    getLoginStatus();
  }, [window.FB]);

  const [
    getPages,
    { loading: getPagesQueryLoading, data: getPagesQueryResult },
  ] = useLazyQuery(GET_PAGES, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getPages({
      variables: {
        mainSuperAdminId,
        source: "facebook",
        onlySelected: false,
      },
    });
  }, [mainSuperAdminId, pageExistingError]);

  useEffect(() => {
    if (getPagesQueryResult?.getAllPages?.length) {
      const pages = getPagesQueryResult.getAllPages.map(
        (curr: any, index: number) => ({
          order: index,
          ...curr,
        }),
      );
      setPagesData([...pages]);
    } else {
      setPagesData([]);
    }
  }, [getPagesQueryResult]);

  // handle existing pages error
  const handleExistingPagesError = (error: string) => {
    setPageExistingError(error);
  };

  return (
    <Box>
      <Box className={classes.box}>Facebook | Messenger</Box>
      {isLoading || getPagesQueryLoading ? (
        <Box
          sx={{
            height: "calc(100vh - 200px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <UpperComponent
            handleExistingPagesError={handleExistingPagesError}
            pageExistingError={pageExistingError}
            setPagesData={setPagesData}
            loggedInUserDetail={loggedInUserDetail}
            setloggedInUserDetail={setloggedInUserDetail}
          />
          {!getPagesQueryLoading && pagesData.length > 0 && (
            <Box
              className={
                loggedInUserDetail
                  ? classes.dynamicHeightLoggedIn
                  : classes.dynamicHeightLoggedOut
              }
            >
              <LowerComponent
                pagesData={pagesData}
                setPagesData={setPagesData}
              />
            </Box>
          )}
          {!getPagesQueryLoading && pagesData.length === 0 && (
            <Box mt={6}>
              <NoRecordFound />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Facebook;
